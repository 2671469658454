<template>
  <header class="header">
    <div class="logo-wrap">
      <!-- <i class="icon-menu icli nav-bar" @click="toggleMenu"></i> -->
      <a href="###">
        <img class="logo" src="@/assets/images/logo/logo_w.jpg" alt="logo" />
      </a>
    </div>
    <div class="avatar-wrap" @click="$router.push('/cart')">
      <!-- <span class="font-sm"><i class="iconly-Location icli font-xl"></i> Los Angeles</span> -->
      <!-- <van-icon name="cart-o" :badge="cartStore.cartNum" /> -->
    </div>
  </header>

  <!-- Main Start -->
  <main class="main-wrap notification-page mb-xxl">
    <div class="nav-wrap">
      <!-- Notification Tabs  Start -->
      <ul class="nav nav-tab2 nav-pills" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation" @click="changeActive(1)">
          <button class="nav-link active" id="alert1-tab" data-bs-toggle="pill" data-bs-target="#alert1" type="button"
            role="tab" aria-controls="alert1" aria-selected="true">最愛</button>
        </li>
        <li class="nav-item" role="presentation" @click="changeActive(2)">
          <button class="nav-link" id="offer1-tab" data-bs-toggle="pill" data-bs-target="#offer1" type="button" role="tab"
            aria-controls="offer1" aria-selected="true">紀錄</button>
        </li>
      </ul>
      <!-- Notification Tabs  End -->
      <!-- <button class="font-md font-theme"><img src="assets/icons/svg/d-check.svg" alt="check" /> Mark all as read</button> -->
    </div>

    <!-- Tab Content Start -->
    <section class="tab-content ratio2_1" id="pills-tabContent" v-if="active == 1">
      <!-- Alert Content Start -->
      <div class="tab-pane fade show active" id="alert1" role="tabpanel" aria-labelledby="alert1-tab">
        <template v-for="(uf, i) in userFavorite" :key="i">
          <van-swipe-cell >
          <div class="offer-wrap">
            <div class="offer-box" >
            <div class="media" @click="goCard(uf.nfcurl)">
              <div class="icon-wrap bg-theme-orange-light avatar">

                <img :src="uf.avatar" alt="avatar" v-if="uf.avatar">
                <img src="@/assets/images/avatar.png" alt="avatar" v-else>
              </div>
              <div class="media-body">
                <h3 class="font-sm title-color">{{ uf.real_name }}</h3>
                <div class="font-xs content-color">{{ uf.company }}</div>
                <div class="font-xs content-color">{{ uf.title }}</div>
              </div>
            </div>
          </div>
          </div>
          <template #right>
            <van-button square text="删除" type="danger" class="delete-button" @click="handleDeleteUF(uf.ufid)"/>
          </template>
        </van-swipe-cell>
        </template>
      </div>
      <!-- Alert Content End -->
    </section>
    <!-- Tab Content End -->

    <section class="tab-content ratio2_1" id="pills-tabContent" v-else>
      <van-steps direction="vertical" :active="0">
        <van-step v-for="(v, i) in userConnections" :key="i">
          <div class="user-conn">
            <div class="user-conn-left">{{ v.name }}</div>
            <div class="user-conn-right">
              <i class="icon-heart" @click="handleAddFavorite(v.userid)">收藏</i>
              <i class="icon-eye-circle" @click="goCard(v.nfcurl)">查看</i>
            </div>
          </div>
          <p>{{ v.company }}</p>
          <p>{{ v.time }}</p>
        </van-step>
      </van-steps>
    </section>
  </main>
  <!-- Main End -->
  <Footer />
</template>

<script setup>
import { ref, onMounted } from 'vue'
import Footer from '@/components/Footer'

import { getUserFavorite, getUserConnections, addFavorite, delFavorite } from '@/api/user'

import { Toast } from 'vant'

const active = ref(1)

const userFavorite = ref()

const userConnections = ref()

onMounted(async () => {
  let res = await getUserFavorite()
  if (res.code === 200) {
    userFavorite.value = res.data
  }
  let res2 = await getUserConnections()
  if (res2.code === 200) {
    userConnections.value = JSON.parse(res2.data)
  }

})

const changeActive = async (type)=>{
  if(type===1){
    active.value = 1
    let res = await getUserFavorite()
    if (res.code === 200) {
      userFavorite.value = res.data
    }
  }else{
    active.value = 2

    let res2 = await getUserConnections()
    if (res2.code === 200) {
      userConnections.value = JSON.parse(res2.data)
    }
  }
}

const goCard = (url) => {
  window.open(url + '&nouc=1&nolog=1', '_blank')
}

const handleAddFavorite = async (userid) => {
  let res = await addFavorite(userid)
  if (res.code === 200) {
    return Toast('收藏成功')
  }
}

const handleDeleteUF = async (id) => {
  let res = await delFavorite(id)
  if(res.code === 200){
    console.log('delete',id,userFavorite.value)

    userFavorite.value = userFavorite.value.filter(item => item.ufid !== id)
  }
}

</script>

<style lang="less" scoped>
.media {
  .avatar {
    img {
      width: 50px;
    }
  }
}

.user-conn {
  display: flex;
  margin-bottom: 10px;

  .user-conn-left {
    flex: 1;
    font-size: 18px;
    font-weight: 500;
  }

  .user-conn-right {
    width: 70px;

    i {
      font-size: 16px;
      margin: 0 5px;
    }
  }
}

.delete-button {
    height: 100%;
    color: #FFF;
  }
</style>